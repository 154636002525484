<template>
  <WeModal v-on:close="onClose" v-bind:title="getTitle" modal-width="80">
    <div
      slot="body"
      class="custom-scrollbar pr-3"
      v-bind:class="{ 'custom-scrollbar modal-body-scroll pr-3': !showDetail }"
    >
      <div v-if="!loading && !showDetail">
        <WeTable
          v-bind:index="false"
          v-bind:columns="columns"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-bind:data="detail.members"
        />
      </div>
      <div class="position-relative" style="min-height: 250px" v-else>
        <WeLoading />
      </div>
    </div>
  </WeModal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "ProductReturnDetail",
  props: {
    detail: {
      default: null,
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      ready: false,
      showDetail: false,
      columns: [
        { name: "name", th: "Müşteri Ad Soyad", type: "string" },
        { name: "email", th: "E-Posta Adresi", type: "string" },
        { name: "phone", th: "Telefon No", type: "string" },
        { name: "created_at", th: "Bildirim Tarihi", type: "datetime" },
      ],
      exportables: ["excel"],
    };
  },
  methods: {
    ...mapActions("orderDetailModal", ["getById"]),
    ...mapActions("tableExport", ["exportData"]),
    onClose(fromDetail = false) {
      if (fromDetail) {
        this.showDetail = false;
      } else {
        this.showDetail = false;
        this.$emit("close");
      }
    },
    onExport(data) {
      if (this.detail.members && this.detail.members.length) {
        let fileName = this.getTitle;
        const filterDates = this.detail.filter.dates;

        if (filterDates && filterDates.length) {
          let startDate = new Date(filterDates[0]).toLocaleDateString("tr-TR");
          let endDate = new Date(filterDates[1]).toLocaleDateString("tr-TR");

          fileName = startDate + " - " + endDate + "_" + fileName;
        }

        this.exportData({
          route: `report/stock-notifications/${this.detail.id}/members/export`,
          exportable: data,
          fileName: fileName,
          filter: {
            ...this.detail.filter,
            type: data.name,
          },
        });
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
  },
  computed: {
    getTitle() {
      return this.showDetail
        ? ""
        : this.detail.title + " - Stok Bildirimi Bekleyen Üyeler";
    },
  },
};
</script>
<style lang="scss">
.modal-body-scroll {
  max-height: 500px;
  overflow-x: auto;
}
</style>